.container-not-found {
    color: #5d5d5d;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.75);
    text-align: center !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-not-found h1 {
    font-size: 2.45em;
    font-weight: 700;
    color: #5d5d5d;
    letter-spacing: -0.02em;
    margin-bottom: 30px;
    margin-top: 30px;
}

.container-not-found .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.container-not-found .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.container-not-found .info {
    color: #5594cf;
    fill: #5594cf;
}

.container-not-found .error {
    color: #c92127;
    fill: #c92127;
}

.container-not-found .warning {
    color: #ffcc33;
    fill: #ffcc33;
}

.container-not-found .success {
    color: #5aba47;
    fill: #5aba47;
}

.container-not-found .icon-large {
    height: 132px;
    width: 132px;
}

.container-not-found .description-text {
    color: #707070;
    letter-spacing: -0.01em;
    font-size: 1.25em;
    line-height: 20px;
}

.container-not-found .footer {
    margin-top: 40px;
    font-size: 0.7em;
}

.container-not-found .delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}