.layout-container {
    height: 100%;
}

.brand-name {
    float: left;
    margin-right: 15px;
    color: rgba(255, 255, 255, 0.65);
    font-size: 20px;
    font-weight: bold;
}

.brand-name img {
    margin-right: 5px;
}

.layout-container .ant-menu-overflow {
    justify-content: end;
}

.layout-container .ant-layout-content {
    padding: 0 2rem;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.no-menu,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected.no-menu,
.ant-menu-dark.ant-menu-horizontal .ant-menu-item.no-menu:hover {
    background-color: transparent;
}

.no-menu {
    cursor: unset;
}

.no-menu .ant-menu-title-content {
    color: rgba(255, 255, 255, 0.90) !important;
}