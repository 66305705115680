body,
html {
  height: unset;
  width: unset;
}

#root {
  min-height: 100%;
  height: inherit;
}

.text-center {
  text-align: center;
}